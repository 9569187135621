import type { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import { cn } from "@/lib/utils";

type HeadlineSize = "huge" | "tall" | "medium" | "regular";

type HeadlineProps<TElementType extends ElementType> =
  ComponentPropsWithoutRef<TElementType> & {
    children: ReactNode;
    size?: HeadlineSize;
    as?: Extract<TElementType, "h1" | "h2" | "h3" | "h4" | "h5" | "p">;
  };

const Headline = <TElementType extends ElementType>({
  children,
  size = "tall",
  as,
  ...rest
}: HeadlineProps<TElementType>) => {
  const Element = as ?? "h2";

  return (
    <Element
      className={cn(
        "break-words font-normal",
        {
          ["text-2.5xl md:text-5xl leading-[2.2rem] md:leading-[3.3rem]"]:
            size === "huge",
          ["text-4.5xl leading-[2.75rem]"]: size === "tall",
          ["text-xl md:text-2xl font-bold leading-[1.375rem] md:leading-[1.65rem]"]:
            size === "medium",
          ["text-xl font-bold leading-[1.375rem]"]: size === "regular",
        },
        rest.className
      )}
    >
      {children}
    </Element>
  );
};

export { Headline };
export type { HeadlineSize };
