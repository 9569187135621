import { createElement } from "react";
import { cn } from "@/lib/utils";

type As = "p" | "span" | "div" | "label";

type CopyTextSize = "tall" | "regular" | "small";

type CopyTextProps = {
  as?: As;
  size?: CopyTextSize;
} & JSX.IntrinsicElements[As];

const CopyText = ({
  as = "p",
  size = "regular",
  className,
  ...rest
}: CopyTextProps) =>
  createElement(as, {
    className: cn(
      {
        ["text-2xl leading-[1.95rem]"]: size === "tall",
        ["text-xl leading-[1.625rem]"]: size === "regular",
        ["text-base leading-[1.2rem]"]: size === "small",
      },
      className
    ),
    ...rest,
  });

export { CopyText };
